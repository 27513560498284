<template>
  <div class="container">
    <alert
      v-if="error"
      type="danger"
      @close="error = false">
      {{errorMessage || `Something went wrong; couldn\'t save your changes.`}}
    </alert>
    <alert
      v-if="success"
      type="success"
      @close="success = false">
      {{successMessage || 'Your changes have been saved successfully.'}}
    </alert>

    <div class="page-header">
      <button
        v-if="can(uiPermissions.MENU_SCHEDULE_UPDATE)"
        data-test="btn-new-market-item"
        class="btn btn-primary d-flex align-items-center"
        @click="handleCreateClick">
        <i class="fe fe-plus mr-2" style="font-size: 1rem"/> Schedule market item
      </button>
    </div>

    <div class="row">
      <div class="col">
        <dimmer :active="isLoading">
          <div v-for="week in items" :key="week.id">
            <accordion
              :hide-footer="true"
              :option="tabs[week.id]"
              custom-class="week-accordion">
              <template v-slot:header="slotProps">
                <div class="d-flex gap-4 align-items-center" @click.stop="slotProps.collapse">
                  <div :class="`week-status-badge-${week.status}`">
                    <p class="m-0 week-status-badge-text">{{week.status}}</p>
                  </div>
                  <h4 class="m-0 ml-2">{{weekName(week.start_date)}}</h4>
                  <button
                    v-if="can(uiPermissions.SUPER_ADMIN)"
                    class="btn ml-auto mr-5"
                    @click.stop="() => copyMarketSchedule(week)">
                    <i class="fe fe-copy" style="font-size: 1rem"/>
                  </button>
                </div>
              </template>

              <div class="d-flex gap-12 flex-wrap p-4">
                <div
                  v-for="recipe in getSortedRecipes(week)"
                  :key="recipe.id"
                  class="max-width-180">
                  <div
                    v-if="recipe.isEmpty"
                    class="card recipe-card empty-recipe-card"
                    :class="`${recipe.type}-bg`">
                    <h6>{{recipe.type}}</h6>
                  </div>
                  <div
                    v-else
                    class="card recipe-card cursor-pointer"
                    @click="() => handleRecipeClick(recipe.id)">
                    <img
                      :src="getTransformationUrl(recipe.photo, {
                        height: 456,
                        width: 'auto',
                        crop: 'fill',
                      })
                      "
                      class="recipe-img"
                      :alt="recipe.cardName1"/>
                    <div class="recipe-content">
                      <div class="recipe-name-section">
                        <div class="recipe-type"><p>{{recipe.type}}</p></div>
                        <h6>{{recipe.cardName1}}</h6>
                        <p>{{recipe.cardName2}}</p>
                      </div>

                      <div class="recipe-info-section">
                        <div class="d-flex align-items-center">
                          <img
                            v-svg-inline
                            data-test="icon-time"
                            class="icon"
                            src="@/assets/svg/time.svg"
                            alt="Cooking time"/>
                          <span>
                            {{(recipe.cookingTime || 0) + (recipe.prep_time || 0)}}
                          </span>

                          <!-- vertical divider -->
                          <div class="divider-vertical"/>

                          <span>
                            AED {{getRecipePrice(recipe)}}
                          </span>
                        </div>

                        <button
                          v-if="can(uiPermissions.MENU_SCHEDULE_UPDATE) && week.status !== MENU_STATUS.PUBLISHED"
                          class="btn btn-secondary remove-btn"
                          @click.stop="() => handleRecipeRemove(week, recipe)">
                          <i class="fe fe-trash delete-icon"></i>
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </accordion>
          </div>

        </dimmer>
      </div>
    </div>

    <choose-market-item-modal
      :show="showChooseItemModal"
      @show="value => showChooseItemModal = value"
      @close="handleCloseChooseItemModal"
      @submit="handleSelectMarketItem"/>

    <choose-weeks-modal
      :show="showChooseWeeksModal"
      @show="value => showChooseWeeksModal = value"
      @close="handleCloseChooseWeeksModal"
      @submit="handleSelectWeeks"/>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
import {market} from '@/services';
import ChooseMarketItemModal from '@/components/view/schedule-market/choose-market-item-modal/index.vue';
import ChooseWeeksModal from '@/components/view/schedule-market/choose-weeks-modal/index.vue';
import {orderBy} from 'lodash';
import {captureException} from '@sentry/vue';

const SCHEDULE_LIMIT = 20;
const NUMBER_OF_PUBLISHED_WEEKS = 4;
const NUMBER_OF_CONCEPT_WEEKS = 4;
const MENU_STATUS = {
  PUBLISHED: 'published',
  CONCEPT: 'concept',
};
const MINIMUM_MARKET_ITEMS = {
  BREAKFAST: 4,
  DESSERT: 2,
  SIDE: 5,
};

export default {
  components: {
    Accordion,
    ChooseMarketItemModal,
    ChooseWeeksModal,
  },
  data() {
    return {
      showChooseItemModal: false,
      showChooseWeeksModal: false,
      selectedMarketItem: null,
      error: false,
      errorMessage: null,
      success: false,
      successMessage: null,
      items: [],
      isLoading: false,
      MENU_STATUS,
      tabs: {},
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleCreateClick() {
      this.showChooseItemModal = true;
    },
    handleCloseChooseItemModal() {
      this.showChooseItemModal = false;
      this.selectedMarketItem = null;
    },
    handleSelectMarketItem(item) {
      this.selectedMarketItem = item;
      this.showChooseWeeksModal = true;
    },
    handleCloseChooseWeeksModal() {
      this.showChooseWeeksModal = false;
    },
    async handleSelectWeeks(weeks) {
      this.errorMessage = null;

      this.showChooseWeeksModal = false;
      this.showChooseItemModal = false;

      const selectedWeeks = weeks.map(week => week.name);

      if (!selectedWeeks.length || !this.selectedMarketItem) {
        this.error = true;
        this.errorMessage = 'Please select a market item and at least one week.';
        window.scrollTo(0, 0);
        return;
      }

      try {
        await market.postSchedule({
          recipe_id: this.selectedMarketItem.id,
          weekly_menu_start_dates: selectedWeeks,
        });

        this.success = true;
        this.fetchData();
      }
      catch (error) {
        this.handleError(error, 'Failed to add market items to schedule.');
      }
      finally {
        window.scrollTo(0, 0);
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const params = {limit: SCHEDULE_LIMIT, status: [MENU_STATUS.PUBLISHED, MENU_STATUS.CONCEPT]};
        this.items = await market.getSchedule({params});

        const publishedWeeks = this.getWeeksBasedOnStatus(this.items, MENU_STATUS.PUBLISHED, NUMBER_OF_PUBLISHED_WEEKS, 'desc');
        const conceptWeeks = this.getWeeksBasedOnStatus(this.items, MENU_STATUS.CONCEPT, NUMBER_OF_CONCEPT_WEEKS, 'asc');

        this.items = [...conceptWeeks, ...publishedWeeks];

        // create tabs based on the weeks ids for more programmatic control
        this.tabs = this.items.reduce((acc, week) => {
          acc[week.id] = {collapse: true, disabled: false, display: true};
          return acc;
        }, {});
      }
      catch (error) {
        this.handleError(error, 'Failed to fetch schedule for market items.');
      }
      finally {
        this.isLoading = false;
      }
    },
    /**
     * Retrieves a subset of weeks based on their status, sorts them by start date and eventually sorts them by descending order.
     *
     * @param {Array} weeks - The array of weeks to filter and sort.
     * @param {string} status - The status to filter the weeks by.
     * @param {number} numberOfWeeks - The number of weeks to include in the subset.
     * @param {string} sortOrder - The sort order for the weeks.
     * @returns {Array} - The subset of weeks sorted by start date in descending order.
     */
    getWeeksBasedOnStatus(weeks, status, numberOfWeeks, sortOrder) {
      const filteredWeeks = weeks.filter(item => item.status === status);
      const sortedWeeks = orderBy(filteredWeeks, 'start_date', sortOrder);
      const subsetWeeks = sortedWeeks.slice(0, numberOfWeeks);
      return orderBy(subsetWeeks, 'start_date', 'desc');
    },
    addEmptyRecipes(recipes, minimumItems, type) {
      if (recipes.length < minimumItems) {
        for (let i = recipes.length; i < minimumItems; i++) {
          recipes.push({
            id: Math.random(),
            type,
            isEmpty: true,
          });
        }
      }
    },
    getSortedRecipes(week) {
      if (!week?.recipes) {
        return [];
      }

      const dessertRecipes = week.recipes.filter(recipe => recipe.type === 'dessert');
      const breakfastRecipes = week.recipes.filter(recipe => recipe.type === 'breakfast');
      const sideRecipes = week.recipes.filter(recipe => recipe.type === 'side');

      this.addEmptyRecipes(dessertRecipes, MINIMUM_MARKET_ITEMS.DESSERT, 'dessert');
      this.addEmptyRecipes(breakfastRecipes, MINIMUM_MARKET_ITEMS.BREAKFAST, 'breakfast');
      this.addEmptyRecipes(sideRecipes, MINIMUM_MARKET_ITEMS.SIDE, 'side');

      return [...dessertRecipes, ...breakfastRecipes, ...sideRecipes];
    },
    handleRecipeClick(id) {
      window.open(`/recipes/${id}`, '_blank');
    },
    getRecipePrice(recipe) {
      return this.numeral(parseFloat(recipe?.unit_price) + parseFloat(recipe?.vat)).format('0,0[.]00');
    },
    async handleRecipeRemove(week, recipe) {
      try {
        this.isLoading = true;
        const weeklyMenuId = week.weekly_menu_id;
        const recipeId = recipe.id;
        await market.removeItemFromSchedule({weeklyMenuId, recipeId});
        this.success = true;
        this.$toasted.success('Market item removed successfully.', {position: 'bottom-center'});
        await this.fetchData();
      }
      catch (error) {
        this.handleError(error, 'Failed to remove market item from schedule.');
      }
      finally {
        this.isLoading = false;
      }
    },
    handleError(error, message) {
      if (!error) {
        return;
      }

      console.error(error);

      this.error = true;
      if (error.response?.status === 422 && error.response?.data.message) {
        this.errorMessage = error.response.data.message;
      }
      else {
        captureException(error);
        this.errorMessage = message;
      }
      this.$toasted.error(this.errorMessage, {position: 'bottom-center'});
    },
    copyMarketSchedule(week) {
      const weekToCopy = {
        [week.start_date]: [
          ...week.recipes.map(recipe => {
            return {
              id: recipe.id,
              name: recipe.name,
              unitPrice: recipe.unit_price,
              vat: recipe.vat,
              servingSize: recipe.serving_size,
              type: recipe.type,
            };
          }),
        ],
      };

      this.copy(JSON.stringify(weekToCopy));
      this.$toasted.show('Market schedule copied to clipboard', {type: 'info', position: 'bottom-center'});
    },
  },
};
</script>

<style scoped>
.recipe-card {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.empty-recipe-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #F7F7F7;
  min-width: 180px;

  h6 {
    margin: 0;
    text-transform: capitalize;
  }
}

.recipe-img {
  width: 100%;
  height: 140px;
  object-fit: cover;
}

.recipe-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  flex: 1;

  h6 {
    color: #454142;
    font-size: 14px;
    font-style: normal;
    font-weight: 590;
    line-height: 140%;
    letter-spacing: -0.42px;
    margin: 0;
  }

  p {
    color: #6C757D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.42px;
    margin: 0;
  }
}

.recipe-type {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 2px;
  margin-bottom: 6px;

  border-radius: 4px;
  background-color: #EDF0F1;
  width: fit-content;

  p {
    color: var(--text-primary, #454142);
    font-size: 0.8rem;
    font-style: normal;
    line-height: 150%;
    letter-spacing: -0.384px;

    text-transform: capitalize;
  }
}

.max-width-180 {
  max-width: 180px;
}

.icon {
  width: 16px;
  height: 16px;
}

.recipe-info-section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  span {
    color: #484848;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    line-height: 130%;
    letter-spacing: -0.42px;
  }

  .remove-btn {
    display: flex;
    height: 36px;
    padding: 6px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 6px;
    border: 1px solid #E0E5ED;
    background-color: #FFF;

    color: #EC6E58;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.42px;
  }
}

.divider-vertical {
  width: 1px;
  height: 70%;
  background-color: #484848;
  margin: 0 8px;
}

.week-status-badge-concept {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #647ad0;
  margin-left: 2px;
  width: 75px;
}

.week-status-badge-published {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #098761;
  margin-left: 2px;
  width: 75px;
}

.week-status-badge-text {
  color: #ffffff;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.42px;
  margin: 0;
  font-weight: 500;
}

.dessert-bg {
  background-color: #fdf7f0;

  h6 {
    color: #8d5416;
  }
}

.breakfast-bg {
  background-color: #f6fcef;

  h6 {
    color: #477718;
  }
}

.side-bg {
  background-color: #fefcf0;

  h6 {
    color: #b09107;
  }
}
</style>

<style>
.week-accordion {
  h5 {
    width: 100% !important;
  }
}
</style>
